import React, { useState } from "react";
import "./AddCarModal.css";

const AddCarModal = ({ onClose, onAddCar }) => {
  const [car, setCar] = useState({
    model: "",
    make: "",
    year: "",
    price: "",
    is_available: true,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCar({ ...car, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddCar(car);
  };

  return (
    <div className="a-modal">
      <div className="a-modal-content">
        <h2>Add New Car</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Model</label>
            <input
              type="text"
              name="model"
              value={car.model}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Make</label>
            <input
              type="text"
              name="make"
              value={car.make}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Year</label>
            <input
              type="number"
              name="year"
              value={car.year}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              name="price"
              value={car.price}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="isAvailable"
                checked={car.isAvailable}
                onChange={(e) =>
                  setCar({ ...car, isAvailable: e.target.checked })
                }
              />
              Available
            </label>
          </div>
          <div className="form-actions">
            <button type="submit">Add Car</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCarModal;
