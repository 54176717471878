import React, { useState } from "react";
import { NavLink } from "react-router-dom"; // Use NavLink instead of Link
import "bootstrap/dist/css/bootstrap.min.css";
import "./Layout.css";

const Layout = ({ children }) => {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [isDarkMode, setDarkMode] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
    document.body.classList.toggle("dark-mode", !isDarkMode);
  };

  return (
    <div className={`layout-container ${isDarkMode ? "dark-mode" : ""}`}>
      <header className="top-bar">
        <div className="top-bar-left">
          <button onClick={toggleSidebar} className="menu-icon">
            ☰
          </button>
          <div className="top-bar-title">Car Showroom Management</div>
        </div>
        <div className="top-bar-right">
          <button onClick={toggleDarkMode} className="dark-mode-switch">
            {isDarkMode ? "☀️ Light Mode" : "🌙 Dark Mode"}
          </button>
          <NavLink to="/profile">Profile</NavLink>
          <NavLink to="/settings">Settings</NavLink>
          <NavLink to="/logout">Logout</NavLink>
        </div>
      </header>

      <div className="container-fluid">
        <div className="row">
          {isSidebarVisible && (
            <nav className="col-md-2 d-none d-md-block sidebar">
              <div className="sidebar-sticky">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/dashboard"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      exact
                      to="/reports"
                    >
                      Reports
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/sales"
                    >
                      Sales
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/credits"
                    >
                      Credits
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/payments"
                    >
                      Payments
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/customers"
                    >
                      Customers
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/cars"
                    >
                      Cars
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          )}
          <main
            role="main"
            className={`main-content col ${
              isSidebarVisible ? "content-with-sidebar" : "content-full-width"
            }`}
          >
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
