import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate to handle redirection
import axios from "axios";
import "./Credits.css";
import config from "../Config";

const Credit = () => {
  const [credits, setCredits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        // Fetch sales with outstanding credit
        const response = await axios.get(`${config.API_BASE_URL}/api/sales`);
        const creditSales = response.data.filter(
          (sale) => sale.credit_amount > 0
        );
        setCredits(creditSales);
        setLoading(false);
      } catch (err) {
        setError("Failed to load credit data. Please try again later.");
        setLoading(false);
      }
    };

    fetchCredits();
  }, []);

  const goToPaymentsPage = (sale) => {
    // Navigate to the Payments page, passing the selected sale's data via state
    navigate("/payments", {
      state: { saleId: sale.sale_id, creditAmount: sale.credit_amount },
    });
  };

  if (loading) {
    return <div>Loading credits...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="credit-container">
      <h1>Outstanding Credits</h1>
      <table className="credit-table">
        <thead>
          <tr>
            <th>Customer</th>
            <th>Car Model</th>
            <th>Sale Amount</th>
            <th>Credit Remaining</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {credits.length > 0 ? (
            credits.map((sale) => (
              <tr key={sale.sale_id}>
                <td>{sale.customer_name}</td>
                <td>{sale.car_model}</td>
                <td>${sale.sale_amount.toFixed(2)}</td>
                <td>${sale.credit_amount.toFixed(2)}</td>
                <td>
                  <button
                    className="pay-btn"
                    onClick={() => goToPaymentsPage(sale)}
                  >
                    Add Payment
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No outstanding credits.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Credit;
