import React, { useEffect, useState } from "react";
import axios from "axios";
import AddPaymentModal from "../modals/AddPaymentModal"; // You'll need to create this component
import EditPaymentModal from "../modals/EditPaymentModal"; // You'll need to create this component
import "./Payments.css"; // You'll need to create this CSS file
import config from "../Config"; // Assuming you have a Config file for base URLs, etc.
import { useLocation } from "react-router-dom"; // Import useLocation to receive the state data

const Payments = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [paymentToEdit, setPaymentToEdit] = useState(null);
  const [saleIdToPay, setSaleIdToPay] = useState(null);

  const location = useLocation(); // Get the passed state from navigation

  useEffect(() => {
    const fetchPaymentsData = async () => {
      try {
        const response = await axios.get(config.API_BASE_URL + "/api/payments");
        setPayments(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load payments data. Please try again later.");
        setLoading(false);
      }
    };

    fetchPaymentsData();
  }, []);

  useEffect(() => {
    if (location.state && location.state.saleId) {
      // Open AddPaymentModal automatically if saleId is passed in the state
      setSaleIdToPay(location.state.saleId);
      setAddModalOpen(true);
    }
  }, [location.state]);

  const addPayment = async (payment) => {
    try {
      const response = await axios.post(
        config.API_BASE_URL + "/api/payments",
        payment
      );

      setPayments([...payments, response.data.message]);
    } catch (error) {
      console.error("There was an error adding the payment:", error);
      alert("There was an error adding the payment");
    }
  };

  const editPayment = async (updatedPayment) => {
    try {
      const response = await axios.put(
        config.API_BASE_URL + `/api/payments/${updatedPayment.payment_id}`,
        updatedPayment
      );
      const updatedPayments = payments.map((payment) =>
        payment.payment_id === updatedPayment.payment_id
          ? response.data.message
          : payment
      );
      setPayments(updatedPayments);
    } catch (error) {
      console.error("There was an error updating the payment:", error);
    }
  };

  const deletePayment = async (paymentId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this payment?"
    );

    if (confirmed) {
      try {
        await axios.delete(config.API_BASE_URL + `/api/payments/${paymentId}`);
        const updatedPayments = payments.filter(
          (payment) => payment.payment_id !== paymentId
        );
        setPayments(updatedPayments);
      } catch (error) {
        console.error("There was an error deleting the payment:", error);
      }
    }
  };

  const handleAddPayment = (newPayment) => {
    try {
      addPayment(newPayment);
      setAddModalOpen(false);
    } catch (error) {
      console.error("There was an error adding the payment:", error);
      alert("Failed to add the payment. Please try again.");
    }
  };

  const handleEditPayment = (updatedPayment) => {
    try {
      editPayment(updatedPayment);
      setEditModalOpen(false);
    } catch (error) {
      console.error("There was an error updating the payment:", error);
      alert("Failed to update the payment. Please try again.");
    }
  };

  const handleDeletePayment = (paymentId) => {
    try {
      deletePayment(paymentId);
    } catch (error) {
      console.error("There was an error deleting the payment:", error);
      alert("Failed to delete the payment. Please try again.");
    }
  };

  const openEditModal = (payment) => {
    setPaymentToEdit(payment);
    setEditModalOpen(true);
  };

  if (loading) {
    return <div>Loading payments data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="payments-container">
      <div className="add-payment">
        <button onClick={() => setAddModalOpen(true)}>Add New Payment</button>
      </div>
      <h1>Payment Records</h1>
      <table className="payments-table">
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>Customer Name</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {payments.length > 0 ? (
            payments.map((payment) => (
              <tr key={payment.payment_id}>
                <td>{payment.payment_id}</td>
                <td>{payment.customer_name}</td>
                <td>${payment.amount}</td>
                <td>{new Date(payment.payment_date).toLocaleDateString()}</td>
                <td>
                  <button
                    className="edit-btn"
                    onClick={() => openEditModal(payment)}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDeletePayment(payment.payment_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No payments available.</td>
            </tr>
          )}
        </tbody>
      </table>

      {isAddModalOpen && (
        <AddPaymentModal
          onClose={() => setAddModalOpen(false)}
          onAddPayment={handleAddPayment}
        />
      )}
      {isEditModalOpen && paymentToEdit && (
        <EditPaymentModal
          paymentData={paymentToEdit}
          onClose={() => setEditModalOpen(false)}
          onEditPayment={handleEditPayment}
        />
      )}
    </div>
  );
};

export default Payments;
