import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EditSaleModal.css"; // Create this CSS file similarly to the others
import config from "../Config";

const EditSaleModal = ({ sale, onClose, onEditSale }) => {
  const [updatedSale, setUpdatedSale] = useState({ ...sale });
  const [customers, setCustomers] = useState([]);
  const [cars, setCars] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customersResponse = await axios.get(
          `${config.API_BASE_URL}/api/customers`
        );
        const carsResponse = await axios.get(`${config.API_BASE_URL}/api/cars`);
        setCustomers(customersResponse.data);
        setCars(carsResponse.data);
        setLoadingData(false);
      } catch (error) {
        setError("Failed to load data.");
        setLoadingData(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedSale((prevSale) => {
      const newSale = { ...prevSale, [name]: value };

      // If the sale amount or amount paid changes, recalculate the credit amount
      if (name === "sale_amount" || name === "amount_paid") {
        const saleAmount = parseFloat(newSale.sale_amount) || 0;
        const amountPaid = parseFloat(newSale.amount_paid) || 0;
        newSale.credit_amount = saleAmount - amountPaid;

        // Validate that the credit amount is not negative
        if (newSale.credit_amount < 0) {
          setValidationError("Credit amount cannot be negative.");
        } else {
          setValidationError("");
        }
      }

      return newSale;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prevent submission if there's a validation error
    if (validationError) {
      alert(validationError);
      return;
    }

    onEditSale(updatedSale);
  };

  if (loadingData) {
    return (
      <div className="se-modal">
        <div className="se-modal-content">
          <div>Loading data...</div>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="se-modal">
        <div className="se-modal-content">
          <div>{error}</div>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  return (
    <div className="se-modal">
      <div className="se-modal-content">
        <h2>Edit Sale</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Customer</label>
            <select
              name="customer_id"
              value={updatedSale.customer_id}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Customer</option>
              {customers.map((customer) => (
                <option key={customer.customer_id} value={customer.customer_id}>
                  {customer.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Car</label>
            <select
              name="car_id"
              value={updatedSale.car_id}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Car</option>
              {cars.map((car) => (
                <option key={car.car_id} value={car.car_id}>
                  {car.model} - {car.make}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Sale Amount</label>
            <input
              type="number"
              name="sale_amount"
              value={updatedSale.sale_amount}
              onChange={handleInputChange}
              required
              min="0"
              step="0.01"
            />
          </div>
          <div className="form-group">
            <label>Amount Paid</label>
            <input
              type="number"
              name="amount_paid"
              value={updatedSale.sale_amount - updatedSale.credit_amount}
              onChange={handleInputChange}
              required
              min="0"
              step="0.01"
            />
          </div>
          <div className="form-group">
            <label>Credit Amount</label>
            <input
              type="number"
              name="credit_amount"
              value={updatedSale.credit_amount}
              readOnly
            />
          </div>
          {validationError && (
            <p className="error-message">{validationError}</p>
          )}
          <div className="form-group">
            <label>Date</label>
            <input
              type="date"
              name="sale_date"
              value={updatedSale.sale_date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit" disabled={!!validationError}>
              Save Changes
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSaleModal;
