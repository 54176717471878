import React, { useEffect, useState } from "react";
import axios from "axios";
import AddSaleModal from "../modals/AddSaleModal";
import EditSaleModal from "../modals/EditSaleModal";
import ViewInvoice from "./ViewInvoice"; // Import the ViewInvoice component
import "./Sales.css";
import config from "../Config";

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false); // State for invoice modal
  const [saleToEdit, setSaleToEdit] = useState(null);
  const [saleToView, setSaleToView] = useState(null); // State for the sale to view in the invoice

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get(config.API_BASE_URL + "/api/sales");
        setSales(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load sales data. Please try again later.");
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  const addSale = async (sale) => {
    try {
      const response = await axios.post(
        config.API_BASE_URL + "/api/sales",
        sale
      );
      const data = await axios.get(
        config.API_BASE_URL + "/api/sales/" + sale.sale_id
      );
      setSales([...sales, data.data[0]]);
    } catch (error) {
      console.error("There was an error adding the sale:", error);
      alert("There was an error adding the sale");
    }
  };

  const editSale = async (updatedSale) => {
    try {
      const response = await axios.put(
        config.API_BASE_URL + `/api/sales/${updatedSale.sale_id}`,
        updatedSale
      );
      const updatedSales = sales.map((sale) =>
        sale.sale_id === updatedSale.sale_id ? response.data.message : sale
      );
      setSales(updatedSales);
    } catch (error) {
      console.error("There was an error updating the sale:", error);
    }
  };

  const deleteSale = async (saleId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this sale?"
    );

    if (confirmed) {
      try {
        await axios.delete(config.API_BASE_URL + `/api/sales/${saleId}`);
        const updatedSales = sales.filter((sale) => sale.sale_id !== saleId);
        setSales(updatedSales);
      } catch (error) {
        console.error("There was an error deleting the sale:", error);
      }
    }
  };

  const handleAddSale = (newSale) => {
    try {
      addSale(newSale);
      setAddModalOpen(false);
    } catch (error) {
      console.error("There was an error adding the sale:", error);
      alert("Failed to add the sale. Please try again.");
    }
  };

  const handleEditSale = (updatedSale) => {
    try {
      editSale(updatedSale);
      setEditModalOpen(false);
    } catch (error) {
      console.error("There was an error updating the sale:", error);
      alert("Failed to update the sale. Please try again.");
    }
  };

  const handleDeleteSale = (saleId) => {
    try {
      deleteSale(saleId);
    } catch (error) {
      console.error("There was an error deleting the sale:", error);
      alert("Failed to delete the sale. Please try again.");
    }
  };

  const openEditModal = (sale) => {
    setSaleToEdit(sale);
    setEditModalOpen(true);
  };

  const openInvoiceModal = (sale) => {
    setSaleToView(sale);
    setInvoiceModalOpen(true);
  };

  if (loading) {
    return <div>Loading sales data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="sales-container">
      <div className="add-sale">
        <button onClick={() => setAddModalOpen(true)}>Add New Sale</button>
      </div>
      <h1>Sales Records</h1>
      <table className="sales-table">
        <thead>
          <tr>
            <th>Sale ID</th>
            <th>Customer Name</th>
            <th>Car Model</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sales.length > 0 ? (
            sales.map((sale) => (
              <tr key={sale.sale_id}>
                <td>{sale.sale_id}</td>
                <td>{sale.customer_name}</td>
                <td>{sale.car_model}</td>
                <td>${sale.sale_amount}</td>
                <td>{new Date(sale.sale_date).toLocaleDateString()}</td>
                <td>
                  {/*  <button
                    className="edit-btn"
                    onClick={() => openEditModal(sale)}
                  >
                    Edit
                  </button>*/}
                  <button
                    className="edit-btn"
                    onClick={() => openInvoiceModal(sale)}
                  >
                    View Invoice
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDeleteSale(sale.sale_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No sales available.</td>
            </tr>
          )}
        </tbody>
      </table>

      {isAddModalOpen && (
        <AddSaleModal
          onClose={() => setAddModalOpen(false)}
          onAddSale={handleAddSale}
        />
      )}
      {isEditModalOpen && saleToEdit && (
        <EditSaleModal
          sale={saleToEdit}
          onClose={() => setEditModalOpen(false)}
          onEditSale={handleEditSale}
        />
      )}
      {isInvoiceModalOpen && saleToView && (
        <ViewInvoice
          saleId={saleToView.sale_id}
          onClose={() => setInvoiceModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Sales;
