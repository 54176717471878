import React, { useState, useEffect } from "react";
import "./EditCarModal.css";

const EditCarModal = ({ car, onClose, onEditCar }) => {
  const [updatedCar, setUpdatedCar] = useState({ ...car });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCar({ ...updatedCar, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onEditCar(updatedCar);
  };

  return (
    <div className="e-modal">
      <div className="e-modal-content">
        <h2>Edit Car</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Model</label>
            <input
              type="text"
              name="model"
              value={updatedCar.model}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Make</label>
            <input
              type="text"
              name="make"
              value={updatedCar.make}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Year</label>
            <input
              type="number"
              name="year"
              value={updatedCar.year}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input
              type="number"
              name="price"
              value={updatedCar.price}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                name="isAvailable"
                checked={updatedCar.is_available}
                onChange={(e) =>
                  setUpdatedCar({
                    ...updatedCar,
                    is_available: e.target.checked,
                  })
                }
              />
              Available
            </label>
          </div>
          <div className="form-actions">
            <button type="submit">Save Changes</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCarModal;
