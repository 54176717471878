import React, { useEffect, useState } from "react";
import axios from "axios";
import AddCustomerModal from "../modals/AddCustomerModal"; // You will need to create this component
import EditCustomerModal from "../modals/EditCustomerModal.js"; // You will need to create this component
import "./Customers.css"; // You may need to create or adjust this CSS file
import config from "../Config"; // Assuming you have a Config file for base URLs, etc.

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState(null);

  useEffect(() => {
    const fetchCustomersData = async () => {
      try {
        const response = await axios.get(
          config.API_BASE_URL + "/api/customers"
        );
        setCustomers(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load customers data. Please try again later.");
        setLoading(false);
      }
    };

    fetchCustomersData();
  }, []);

  const addCustomer = async (customer) => {
    try {
      const response = await axios.post(
        config.API_BASE_URL + "/api/customers",
        customer
      ); // POST request to add a customer
      setCustomers([...customers, response.data.message]); // Update state with new customer
    } catch (error) {
      console.error("There was an error adding the customer:", error);
      alert("There was an error adding the customer");
    }
  };

  const editCustomer = async (updatedCustomer) => {
    try {
      const response = await axios.put(
        config.API_BASE_URL + `/api/customers/${updatedCustomer.customer_id}`,
        updatedCustomer
      );
      const updatedCustomers = customers.map((customer) =>
        customer.customer_id === updatedCustomer.customer_id
          ? response.data.message
          : customer
      );
      setCustomers(updatedCustomers);
    } catch (error) {
      console.error("There was an error updating the customer:", error);
      alert("There was an error adding the customer");
    }
  };

  const deleteCustomer = async (customerId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this customer?"
    );

    if (confirmed) {
      try {
        await axios.delete(
          config.API_BASE_URL + `/api/customers/${customerId}`
        );
        const updatedCustomers = customers.filter(
          (customer) => customer.customer_id !== customerId
        );
        setCustomers(updatedCustomers);
      } catch (error) {
        console.error("There was an error deleting the customer:", error);
      }
    }
  };

  const handleAddCustomer = (newCustomer) => {
    try {
      addCustomer(newCustomer);
      setAddModalOpen(false);
    } catch (error) {
      console.error("There was an error adding the customer:", error);
      alert("Failed to add the customer. Please try again.");
    }
  };

  const handleEditCustomer = (updatedCustomer) => {
    try {
      editCustomer(updatedCustomer);
      setEditModalOpen(false);
    } catch (error) {
      console.error("There was an error updating the customer:", error);
      alert("Failed to update the customer. Please try again.");
    }
  };

  const handleDeleteCustomer = (customerId) => {
    try {
      deleteCustomer(customerId);
    } catch (error) {
      console.error("There was an error deleting the customer:", error);
      alert("Failed to delete the customer. Please try again.");
    }
  };

  const openEditModal = (customer) => {
    setCustomerToEdit(customer);
    setEditModalOpen(true);
  };

  if (loading) {
    return <div>Loading customers data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="customers-container">
      <div className="add-customer">
        <button onClick={() => setAddModalOpen(true)}>Add New Customer</button>
      </div>
      <h1>Customer List</h1>
      <table className="customers-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers.length > 0 ? (
            customers.map((customer) => (
              <tr key={customer.customer_id}>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.phone_number}</td>
                <td>{customer.address}</td>
                <td>
                  <button
                    className="edit-btn"
                    onClick={() => openEditModal(customer)}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDeleteCustomer(customer.customer_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No customers available.</td>
            </tr>
          )}
        </tbody>
      </table>

      {isAddModalOpen && (
        <AddCustomerModal
          onClose={() => setAddModalOpen(false)}
          onAddCustomer={handleAddCustomer}
        />
      )}
      {isEditModalOpen && customerToEdit && (
        <EditCustomerModal
          customer={customerToEdit}
          onClose={() => setEditModalOpen(false)}
          onEditCustomer={handleEditCustomer}
        />
      )}
    </div>
  );
};

export default Customers;
