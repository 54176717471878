import React, { useState, useEffect } from "react";
import "./Reports.css"; // Assuming this file exists for
import config from "../Config";

const Reports = () => {
  const [reportType, setReportType] = useState("sales");
  const [dateRange, setDateRange] = useState("Today");
  const [groupBy, setGroupBy] = useState("Date");
  const [reportName, setReportName] = useState("");
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Automatically fetch data when the reportType, dateRange, or groupBy changes
    fetchReportData();
  }, [reportType, dateRange, groupBy]);

  const fetchReportData = async () => {
    setLoading(true);
    setError(null); // Reset error
    try {
      const response = await fetch(
        config.API_BASE_URL +
          `/api/stats?reportType=${reportType}&dateRange=${encodeURIComponent(
            dateRange
          )}&groupBy=${encodeURIComponent(groupBy)}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch report data");
      }
      const data = await response.json();
      setReportData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetStats = () => {
    fetchReportData();
  };

  const handleAddFilter = () => {
    // Logic for adding filters (if needed)
    console.log("Adding filter...");
  };

  const renderTableHeaders = () => {
    if (reportType === "sales") {
      return (
        <tr>
          <th>{groupBy === "Customer" ? "Customer Name" : "Date"}</th>
          <th>Sale Amount</th>
          <th>Sales Count</th>
        </tr>
      );
    } else if (reportType === "payments") {
      return (
        <tr>
          <th>{groupBy === "Customer" ? "Customer Name" : "Date"}</th>
          <th>Payment Amount</th>
          <th>Payments Count</th>
        </tr>
      );
    } else if (reportType === "credits") {
      return (
        <tr>
          <th>{groupBy === "Customer" ? "Customer Name" : "Date"}</th>
          <th>Credit Amount</th>
        </tr>
      );
    } else if (reportType === "inventory") {
      return (
        <tr>
          <th>Car Model</th>
          <th>Status</th>
          <th>Sale Date</th>
        </tr>
      );
    }
  };

  const renderTableRows = () => {
    if (loading) {
      return (
        <tr>
          <td colSpan="6">Loading...</td>
        </tr>
      );
    }

    if (error) {
      return (
        <tr>
          <td colSpan="6">Error: {error}</td>
        </tr>
      );
    }

    if (reportData.length === 0) {
      return (
        <tr>
          <td colSpan="6">No rows found</td>
        </tr>
      );
    }

    return reportData.map((row, index) => {
      if (reportType === "sales") {
        return (
          <tr key={index}>
            <td>{row.group_key}</td>
            <td>${row.total_sales}</td>
            <td>{row.sales_count}</td>
          </tr>
        );
      } else if (reportType === "payments") {
        return (
          <tr key={index}>
            <td>{row.group_key}</td>
            <td>${row.total_payments}</td>
            <td>{row.payments_count}</td>
          </tr>
        );
      } else if (reportType === "credits") {
        return (
          <tr key={index}>
            <td>{row.group_key}</td>
            <td>${row.total_credits}</td>
          </tr>
        );
      } else if (reportType === "inventory") {
        return (
          <tr key={index}>
            <td>{row.model}</td>
            <td>{row.status}</td>
            <td>
              {row.sale_date
                ? new Date(row.sale_date).toLocaleDateString()
                : "N/A"}
            </td>
          </tr>
        );
      }
      return null;
    });
  };

  return (
    <div className="reports-container">
      {/* Report Type Selection */}
      <div className="reports-type-selection">
        <label>Report Type:</label>
        <select
          value={reportType}
          onChange={(e) => setReportType(e.target.value)}
        >
          <option value="sales">Sales Report</option>
          <option value="payments">Payments Report</option>
          <option value="credits">Credits Report</option>
          <option value="inventory">Inventory Report</option>
        </select>
      </div>

      {/* Header Section */}
      <div className="reports-header">
        <div className="header-item">
          <label>Date Range</label>
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
          >
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
            <option value="Last 7 Days">Last 7 Days</option>
            <option value="This Month">This Month</option>
            <option value="Last Month">Last Month</option>
          </select>
        </div>
        <div className="header-item">
          <label>Group By</label>
          <select value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
            <option value="Date">Date</option>
            <option value="Customer">Customer</option>
            {reportType === "sales" && <option value="Car">Car</option>}
          </select>
        </div>
        <div className="header-item">
          <label>Report Name</label>
          <input
            type="text"
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
            placeholder="Name"
          />
          <button
            className="save-button"
            onClick={() => console.log("Saving report")}
          >
            Save
          </button>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="reports-actions">
        <button className="get-stats-button" onClick={handleGetStats}>
          Get Stats
        </button>
        <button className="add-filter-button" onClick={handleAddFilter}>
          + Add Filter
        </button>
      </div>

      {/* Table Section */}
      <div className="reports-stats">
        <h3>
          {reportType.charAt(0).toUpperCase() + reportType.slice(1)} Stats
        </h3>
        <table className="stats-table">
          <thead>{renderTableHeaders()}</thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>

      {/* Pagination (if needed) */}
      <div className="reports-pagination">
        <span>Page 1 of 1</span>
        <select>
          <option>10 rows</option>
          <option>25 rows</option>
          <option>50 rows</option>
        </select>
      </div>
    </div>
  );
};

export default Reports;
