const config = {
  //API_BASE_URL: "https://beeauto.gsosa.workers.dev", // Replace with your actual API base URL
  API_BASE_URL: "https://beeautoapp.gsosa.workers.dev", // Replace with your actual API base URL
  TIMEOUT: 10000, // Axios request timeout in milliseconds
  DEFAULT_HEADERS: {
    "Content-Type": "application/json",
  },
};

export default config;
