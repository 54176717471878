import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../Config";
import "./ViewInvoice.css";

const ViewInvoice = ({ saleId, onClose }) => {
  const [sale, setSale] = useState(null);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSaleAndPayments = async () => {
      try {
        const saleResponse = await axios.get(
          `${config.API_BASE_URL}/api/sales/${saleId}`
        );
        const paymentsResponse = await axios.get(
          `${config.API_BASE_URL}/api/payments?sale_id=${saleId}`
        );
        setSale(saleResponse.data[0]);
        setPayments(paymentsResponse.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load sale details.");
        setLoading(false);
      }
    };

    fetchSaleAndPayments();
  }, [saleId]);

  if (loading) {
    return <div>Loading invoice...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="invoice-modal">
      <div className="invoice-modal-content">
        <h2>Invoice</h2>
        <p>Customer: {sale.customer_name}</p>
        <p>Car: {sale.car_model}</p>
        <p>Sale Amount: ${sale.sale_amount}</p>
        <p>Credit Amount: ${sale.credit_amount}</p>
        <p>Date: {new Date(sale.sale_date).toLocaleDateString("en-GB")}</p>
        <h3>Payments</h3>
        <ul>
          {payments.map((payment) => (
            <li key={payment.payment_id}>
              ${payment.amount} on {payment.payment_date}
            </li>
          ))}
        </ul>
        <div className="invoice-actions">
          <button onClick={() => window.print()}>Print Invoice</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ViewInvoice;
