import React, { useState, useEffect } from "react";
import "./EditCustomerModal.css"; // Create this CSS file similarly to how you did for cars

const EditCustomerModal = ({ customer, onClose, onEditCustomer }) => {
  const [updatedCustomer, setUpdatedCustomer] = useState({ ...customer });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCustomer({ ...updatedCustomer, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onEditCustomer(updatedCustomer);
  };

  return (
    <div className="ec-modal">
      <div className="ec-modal-content">
        <h2>Edit Customer</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={updatedCustomer.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={updatedCustomer.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="text"
              name="phone_number"
              value={updatedCustomer.phone_number}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={updatedCustomer.address}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit">Save Changes</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCustomerModal;
