import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate to handle navigation
import { Line, Pie } from "react-chartjs-2";
import "./Dashboard.css";
import config from "../Config";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {
  const [metrics, setMetrics] = useState({
    totalSales: 0,
    totalRevenue: 0,
    carsInInventory: 0,
    outstandingCredits: 0,
  });
  const [salesData, setSalesData] = useState(null);
  const [revenueData, setRevenueData] = useState(null);
  const [creditData, setCreditData] = useState(null);
  const [recentSales, setRecentSales] = useState([]);
  const [recentPayments, setRecentPayments] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const [error, setError] = useState(null); // State to track errors

  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch key metrics
        const metricsResponse = await axios.get(
          config.API_BASE_URL + "/api/reports/metrics"
        );
        const metricsData = metricsResponse.data;

        // Animate metrics from 0 to their actual value over 4 seconds
        animateMetrics(metricsData);

        // Fetch sales data for the chart
        const salesResponse = await axios.get(
          config.API_BASE_URL + "/api/reports/sales_by_month"
        );
        const months = salesResponse.data.map((data) => data.month);
        const totalSales = salesResponse.data.map((data) => data.total_sales);
        setSalesData({
          labels: months,
          datasets: [
            {
              label: "Sales Over Time",
              data: totalSales,
              borderColor: "#17a2b8",
              backgroundColor: "rgba(23, 162, 184, 0.5)",
            },
          ],
        });

        // Fetch revenue data for the chart
        const revenueResponse = await axios.get(
          config.API_BASE_URL + "/api/reports/payments_by_month"
        );
        const revenueData = revenueResponse.data;
        const revmonths = revenueData.map((data) => data.month);
        const revtotalSales = revenueData.map((data) => data.total_payments);
        setRevenueData({
          labels: revmonths,
          datasets: [
            {
              label: "Payments Over Time",
              data: revtotalSales,
              backgroundColor: ["#17a2b8", "#28a745", "#ffc107", "#dc3545"],
            },
          ],
        });

        // Fetch credit data for the chart
        const creditResponse = await axios.get(
          config.API_BASE_URL + "/api/reports/credits_by_month"
        );
        const creditData = creditResponse.data;
        const crdmonths = creditData.map((data) => data.month);
        const crftotalSales = creditData.map((data) => data.total_credits);
        setCreditData({
          labels: crdmonths,
          datasets: [
            {
              label: "Credits Over Time",
              data: crftotalSales,
              backgroundColor: ["#17a2b8", "#28a745", "#ffc107", "#dc3545"],
            },
          ],
        });

        // Fetch recent sales
        const recentSalesResponse = await axios.get(
          config.API_BASE_URL + "/api/reports/recent_sales"
        );
        setRecentSales(recentSalesResponse.data);

        // Fetch recent payments
        const recentPaymentsResponse = await axios.get(
          config.API_BASE_URL + "/api/reports/recent_payments"
        );
        setRecentPayments(recentPaymentsResponse.data);

        setLoading(false); // Set loading to false when all data is fetched
      } catch (err) {
        setError(
          "Failed to load dashboard data. Please try again later.:" +
            err.message
        );
        setLoading(false); // Set loading to false even if there is an error
      }
    };

    const animateMetrics = (metricsData) => {
      const duration = 4000; // 4 seconds
      const stepTime = 50; // Time between steps (ms)
      const steps = duration / stepTime;

      const animate = (metric, finalValue) => {
        let currentStep = 0;
        const increment = finalValue / steps;

        const interval = setInterval(() => {
          currentStep++;
          setMetrics((prevMetrics) => ({
            ...prevMetrics,
            [metric]: Math.min(
              Math.floor(prevMetrics[metric] + increment),
              finalValue
            ),
          }));
          if (currentStep >= steps) {
            clearInterval(interval);
            setMetrics((prevMetrics) => ({
              ...prevMetrics,
              [metric]: finalValue,
            }));
          }
        }, stepTime);
      };

      animate("totalSales", metricsData.totalSales);
      animate("totalRevenue", metricsData.totalRevenue);
      animate("carsInInventory", metricsData.carsInInventory);
      animate("outstandingCredits", metricsData.outstandingCredits);
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <div>Loading dashboard data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="quick-actions">
        <button onClick={() => navigate("/cars")}>Add New Car</button>
        <button onClick={() => navigate("/sales")}>Add New Sale</button>
        <button onClick={() => navigate("/payments")}>Add New Payment</button>
        <button onClick={() => navigate("/customers")}>Add New Customer</button>
        <button onClick={() => navigate("/reports")}>View Reports</button>
      </div>

      <div className="metrics-overview">
        <div className="card">
          <h3>Total Sales</h3>
          <p>{metrics.totalSales}</p>
        </div>
        <div className="card">
          <h3>Total Revenue</h3>
          <p>${metrics.totalRevenue}</p>
        </div>
        <div className="card">
          <h3>Outstanding Credits</h3>
          <p>${metrics.outstandingCredits}</p>
        </div>
        <div className="card">
          <h3>Cars in Inventory</h3>
          <p>{metrics.carsInInventory}</p>
        </div>
      </div>

      <div className="charts-overview">
        <div className="chartcard">
          <h2>Sales by months</h2>
          {salesData ? (
            <Line
              data={salesData}
              options={{
                scales: {
                  y: {
                    beginAtZero: true, // Ensure y-axis starts at 0
                  },
                },
                plugins: {
                  legend: {
                    display: true, // Show legend
                  },
                },
              }}
              width={400}
              height={400}
            />
          ) : (
            <p>No data available.</p>
          )}
        </div>
        <div className="chartcard">
          <h2>Payments by months</h2>
          {revenueData ? (
            <Line
              data={revenueData}
              options={{
                plugins: {
                  legend: {
                    display: true, // Show legend
                  },
                },
              }}
              width={400}
              height={400}
            />
          ) : (
            <p>No data available.</p>
          )}
        </div>
        <div className="chartcard">
          <h2>Credits by months</h2>
          {creditData ? (
            <Line
              data={creditData}
              options={{
                plugins: {
                  legend: {
                    display: true, // Show legend
                  },
                },
              }}
              width={400}
              height={400}
            />
          ) : (
            <p>No data available.</p>
          )}
        </div>
      </div>

      <div className="recent-activity">
        <div className="listcard">
          <h2>Recent Sales</h2>
          {recentSales.length > 0 ? (
            <table className="activity-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Car Model</th>
                  <th>Sale Amount</th>
                </tr>
              </thead>
              <tbody>
                {recentSales.map((sale) => (
                  <tr key={sale.sale_id}>
                    <td>{new Date(sale.sale_date).toLocaleDateString()}</td>
                    <td>{sale.customer_name}</td>
                    <td>{sale.car_model}</td>
                    <td>${sale.sale_amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No recent sales available.</p>
          )}
        </div>
        <div className="listcard">
          <h2>Recent Payments</h2>
          {recentPayments.length > 0 ? (
            <table className="activity-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Customer Name</th>
                  <th>Payment Amount</th>
                </tr>
              </thead>
              <tbody>
                {recentPayments.map((payment) => (
                  <tr key={payment.payment_id}>
                    <td>
                      {new Date(payment.payment_date).toLocaleDateString()}
                    </td>
                    <td>{payment.customer_name}</td>
                    <td>${payment.payment_amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No recent payments available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
