import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddPaymentModal.css";
import config from "../Config";

const AddPaymentModal = ({ onClose, onAddPayment }) => {
  const [payment, setPayment] = useState({
    sale_id: "",
    amount: "",
    payment_date: "",
  });

  const [sales, setSales] = useState([]);
  const [selectedSale, setSelectedSale] = useState(null);
  const [remainingCredit, setRemainingCredit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api/sales`);
        setSales(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load sales data.");
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "sale_id") {
      const sale = sales.find((s) => s.sale_id === value);
      setSelectedSale(sale);
      setRemainingCredit(sale.credit_amount); // Initialize remaining credit with the selected sale's credit amount
    }

    if (name === "amount" && selectedSale) {
      const paymentAmount = parseFloat(value) || 0;
      const newRemainingCredit = selectedSale.credit_amount - paymentAmount;

      if (newRemainingCredit < 0) {
        setValidationError("Payment amount cannot exceed the credit amount.");
      } else {
        setValidationError("");
        setRemainingCredit(newRemainingCredit); // Update remaining credit dynamically
      }
    }

    setPayment((prevPayment) => ({
      ...prevPayment,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationError) {
      alert(validationError);
      return;
    }
    if (!payment.sale_id) {
      alert("Please select a sale to add the payment to.");
      return;
    }
    if (parseFloat(payment.amount) > selectedSale.credit_amount) {
      alert("Payment amount cannot exceed the credit amount.");
      return;
    }

    // Construct the full payment data object
    const paymentData = {
      sale_id: payment.sale_id,
      customer_name: selectedSale.customer_name,
      customer_id: selectedSale.customer_id, // Get customer_id from the selected sale
      amount: payment.amount,
      payment_date: payment.payment_date,
      created_at: new Date().toISOString(), // Set the creation timestamp
      is_new: false,
    };

    onAddPayment(paymentData);
  };

  if (loading) {
    return <div>Loading sales data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="pa-modal">
      <div className="pa-modal-content">
        <h2>Add New Payment</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Select Sale</label>
            <select
              name="sale_id"
              value={payment.sale_id}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a Sale</option>
              {sales.map((sale) => (
                <option key={sale.sale_id} value={sale.sale_id}>
                  {sale.customer_name} - {sale.car_model} (${sale.sale_amount})
                </option>
              ))}
            </select>
          </div>
          {selectedSale && (
            <>
              <div className="form-group">
                <label>Credit Amount Remaining</label>
                <p>${remainingCredit.toFixed(2)}</p>{" "}
                {/* Display the dynamically updated remaining credit */}
              </div>
            </>
          )}
          <div className="form-group">
            <label>Payment Amount</label>
            <input
              type="number"
              name="amount"
              value={payment.amount}
              onChange={handleInputChange}
              required
              min="0"
              step="0.01"
              max={selectedSale ? selectedSale.credit_amount : ""}
            />
          </div>
          {validationError && (
            <p className="error-message">{validationError}</p>
          )}
          <div className="form-group">
            <label>Date</label>
            <input
              type="date"
              name="payment_date"
              value={payment.payment_date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit" disabled={!!validationError}>
              Add Payment
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPaymentModal;
