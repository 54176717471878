import React, { useEffect, useState } from "react";
import axios from "axios";
import AddCarModal from "../modals/AddCarModal";
import EditCarModal from "../modals/EditCarModal.js";
import "./Cars.css";
import config from "../Config";

const Cars = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [carToEdit, setCarToEdit] = useState(null);

  useEffect(() => {
    const fetchCarsData = async () => {
      try {
        const response = await axios.get(config.API_BASE_URL + "/api/cars");
        setCars(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load cars data. Please try again later.");
        setLoading(false);
      }
    };

    fetchCarsData();
  }, []);

  const addCar = async (car) => {
    try {
      const response = await axios.post(config.API_BASE_URL + "/api/cars", car); // Sending POST request to add the car
      setCars([...cars, response.data.message]); // Updating the state with the new car data
    } catch (error) {
      console.error("There was an error adding the car:", error);
    }
  };

  const editCar = async (updatedCar) => {
    try {
      const response = await axios.put(
        config.API_BASE_URL + `/api/cars/${updatedCar.car_id}`,
        updatedCar
      );
      const updatedCars = cars.map((car) =>
        car.car_id === updatedCar.car_id ? response.data.message : car
      );
      setCars(updatedCars);
    } catch (error) {
      console.error("There was an error updating the car:", error);
    }
  };

  const deleteCar = async (carId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this car?"
    );

    if (confirmed) {
      try {
        await axios.delete(config.API_BASE_URL + `/api/cars/${carId}`);
        const updatedCars = cars.filter((car) => car.car_id !== carId);
        setCars(updatedCars);
      } catch (error) {
        console.error("There was an error deleting the car:", error);
      }
    }
  };

  const handleAddCar = (newCar) => {
    try {
      addCar(newCar);

      setAddModalOpen(false);
    } catch (error) {
      console.error("There was an error adding the car:", error);
      alert("Failed to add the car. Please try again.");
    }
  };

  const handleEditCar = (updatedCar) => {
    try {
      editCar(updatedCar);
      setEditModalOpen(false);
    } catch (error) {
      console.error("There was an error adding the car:", error);
      alert("Failed to update the car. Please try again.");
    }
  };

  const handleDeleteCar = (carId) => {
    try {
      deleteCar(carId);
    } catch (error) {
      console.error("There was an error adding the car:", error);
      alert("Failed to update the car. Please try again.");
    }
    // Ideally, you'd make a DELETE request to the API to remove the car.
  };

  const openEditModal = (car) => {
    setCarToEdit(car);
    setEditModalOpen(true);
  };

  if (loading) {
    return <div>Loading cars data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="cars-container">
      <div className="add-car">
        <button
          onClick={() => {
            console.log("Button clicked, opening modal...");
            setAddModalOpen(true);
          }}
        >
          Add New Car
        </button>
      </div>
      <h1>Car Inventory</h1>
      <table className="cars-table">
        <thead>
          <tr>
            <th>Model</th>
            <th>Make</th>
            <th>Year</th>
            <th>Price</th>
            <th>Availability</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cars.length > 0 ? (
            cars.map((car) => (
              <tr key={car.id}>
                <td>{car.model}</td>
                <td>{car.make}</td>
                <td>{car.year}</td>
                <td>${car.price}</td>
                <td>{car.is_available ? "Available" : "Sold"}</td>
                <td>
                  <button
                    className="edit-btn"
                    onClick={() => openEditModal(car)}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-btn"
                    onClick={() => handleDeleteCar(car.car_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No cars available in inventory.</td>
            </tr>
          )}
        </tbody>
      </table>

      {isAddModalOpen && (
        <AddCarModal
          onClose={() => setAddModalOpen(false)}
          onAddCar={handleAddCar}
        />
      )}
      {isEditModalOpen && carToEdit && (
        <EditCarModal
          car={carToEdit}
          onClose={() => setEditModalOpen(false)}
          onEditCar={handleEditCar}
        />
      )}
    </div>
  );
};

export default Cars;
