import React, { useState, useEffect } from "react";
import axios from "axios";
import "./EditPaymentModal.css";
import config from "../Config";

const EditPaymentModal = ({ paymentData, onClose, onEditPayment }) => {
  const [payment, setPayment] = useState({
    amount: paymentData.amount,
    payment_date: paymentData.payment_date,
  });

  const [selectedSale, setSelectedSale] = useState(null);
  const [remainingCredit, setRemainingCredit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    const fetchSaleData = async () => {
      try {
        // Fetch the sale data based on the sale_id from paymentData
        const response = await axios.get(
          `${config.API_BASE_URL}/api/sales/${paymentData.sale_id}`
        );
        const sale = response.data[0];
        setSelectedSale(sale);

        // Initialize remaining credit amount
        setRemainingCredit(sale.credit_amount); // Include the current payment amount
        setLoading(false);
      } catch (err) {
        setError("Failed to load sale data.");
        setLoading(false);
      }
    };

    fetchSaleData();
  }, [paymentData.sale_id, paymentData.amount]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount" && selectedSale) {
      const paymentAmount = parseFloat(value) || 0;
      const newRemainingCredit =
        selectedSale.credit_amount +
        parseFloat(paymentData.amount) -
        paymentAmount;

      if (newRemainingCredit < 0) {
        setValidationError(
          "Payment amount cannot exceed the remaining credit."
        );
      } else {
        setValidationError("");
        setRemainingCredit(newRemainingCredit); // Update remaining credit dynamically
      }
    }

    setPayment((prevPayment) => ({
      ...prevPayment,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationError) {
      alert(validationError);
      return;
    }
    if (
      parseFloat(payment.amount) >
      selectedSale.credit_amount + parseFloat(paymentData.amount)
    ) {
      alert("Payment amount cannot exceed the credit amount.");
      return;
    }

    // Construct the updated payment data object
    const updatedPaymentData = {
      payment_id: paymentData.payment_id,
      sale_id: paymentData.sale_id, // Sale ID remains the same
      customer_name: selectedSale.customer_name,
      customer_id: selectedSale.customer_id, // Get customer_id from the selected sale
      amount: payment.amount,
      payment_date: payment.payment_date,
      updated_at: new Date().toISOString(), // Set the updated timestamp
      sale_credit: remainingCredit,
    };

    onEditPayment(updatedPaymentData);
  };

  if (loading) {
    return <div>Loading sale data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="pe-modal">
      <div className="pe-modal-content">
        <h2>Edit Payment</h2>
        <form onSubmit={handleSubmit}>
          {selectedSale && (
            <>
              <div className="form-group">
                <label>Customer Name</label>
                <p>{selectedSale.customer_name}</p>
              </div>
              <div className="form-group">
                <label>Car Model</label>
                <p>{selectedSale.car_model}</p>
              </div>
              <div className="form-group">
                <label>Sale Amount</label>
                <p>${selectedSale.sale_amount}</p>
              </div>
              <div className="form-group">
                <label>Credit Amount Remaining</label>
                <p>${remainingCredit.toFixed(2)}</p>{" "}
                {/* Display the dynamically updated remaining credit */}
              </div>
            </>
          )}
          <div className="form-group">
            <label>Payment Amount</label>
            <input
              type="number"
              name="amount"
              value={payment.amount}
              onChange={handleInputChange}
              required
              min="0"
              step="0.01"
              max={
                selectedSale
                  ? selectedSale.credit_amount + parseFloat(paymentData.amount)
                  : ""
              }
            />
          </div>
          {validationError && (
            <p className="error-message">{validationError}</p>
          )}
          <div className="form-group">
            <label>Date</label>
            <input
              type="date"
              name="payment_date"
              value={payment.payment_date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit" disabled={!!validationError}>
              Save Changes
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPaymentModal;
