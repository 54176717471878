import React, { useState } from "react";
import "./AddCustomerModal.css"; // Create this CSS file similarly to how you did for cars

const AddCustomerModal = ({ onClose, onAddCustomer }) => {
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    phone_number: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    setCustomer({ ...customer, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddCustomer(customer);
  };

  return (
    <div className="ac-modal">
      <div className="ac-modal-content">
        <h2>Add New Customer</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              value={customer.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={customer.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="text"
              name="phone_number"
              value={customer.phone_number}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={customer.address}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit">Add Customer</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCustomerModal;
