import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddSaleModal.css"; // Ensure your styles are consistent
import config from "../Config";

// UUID generator function
function generateUUID(prefix) {
  const id = Math.random().toString(36).substring(2, 10).toUpperCase();
  return `${prefix}${id}`;
}

const AddSaleModal = ({ onClose, onAddSale }) => {
  const [sale, setSale] = useState({
    customer_id: "",
    customer_name: "",
    car_id: "",
    sale_amount: "",
    amount_paid: "",
    sale_date: "",
    credit_amount: 0,
  });

  const [customers, setCustomers] = useState([]);
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customersResponse = await axios.get(
          `${config.API_BASE_URL}/api/customers`
        );
        const carsResponse = await axios.get(`${config.API_BASE_URL}/api/cars`);
        setCustomers(customersResponse.data);
        setCars(carsResponse.data);
        setLoadingData(false);
      } catch (error) {
        setError("Failed to load data.");
        setLoadingData(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSale((prevSale) => {
      const updatedSale = { ...prevSale, [name]: value };

      if (name === "customer_id") {
        updatedSale.customer_name =
          e.target.options[e.target.selectedIndex].getAttribute("data-name");
        console.log(updatedSale.customer_name);
      }

      // Calculate credit amount if sale_amount and amount_paid are available
      if (name === "sale_amount" || name === "amount_paid") {
        const saleAmount = parseFloat(updatedSale.sale_amount) || 0;
        const amountPaid = parseFloat(updatedSale.amount_paid) || 0;
        updatedSale.credit_amount = saleAmount - amountPaid;

        // Check if credit amount is negative
        if (updatedSale.credit_amount < 0) {
          setValidationError("Credit amount cannot be negative.");
          var p = document.querySelector('button[type="submit"]');
          p.style.backgroundColor = "gray";
        } else {
          setValidationError("");
          var p = document.querySelector('button[type="submit"]');
          p.style.backgroundColor = "#28a745";
        }
      }

      return updatedSale;
    });
  };

  const handleCarChange = (e) => {
    const carId = e.target.value;
    const car = cars.find((c) => c.car_id === carId);
    setSelectedCar(car);
    setSale((prevSale) => ({
      ...prevSale,
      car_id: carId,
      sale_amount: car ? car.price : "", // Default the sale amount to the car's price
      amount_paid: "",
      credit_amount: car ? car.price : 0, // Reset credit amount
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prevent form submission if credit amount is negative
    if (sale.credit_amount < 0) {
      setValidationError("Credit amount cannot be negative.");
      return;
    }

    // Generate sale_id using the UUID function
    const newSale = {
      ...sale,
      sale_id: generateUUID("sa"), // Generate a unique sale ID
      created_at: new Date().toISOString(),
    };

    try {
      // Add the sale to the sales table
      await onAddSale(newSale);
      await new Promise((resolve) => setTimeout(resolve, 3000));

      // Add the payment to the payments table
      const paymentData = {
        sale_id: newSale.sale_id,
        customer_id: newSale.customer_id,
        customer_name: newSale.customer_name,
        amount: sale.amount_paid,
        payment_date: new Date().toISOString(),
        created_at: new Date().toISOString(),
        is_new: true,
      };

      await axios.post(`${config.API_BASE_URL}/api/payments`, paymentData);

      onClose();
    } catch (error) {
      console.error("There was an error adding the sale or payment:", error);
      alert("Failed to add the sale. Please try again.");
    }
  };

  if (loadingData) {
    return (
      <div className="sa-modal">
        <div className="sa-modal-content">
          <div>Loading data...</div>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="sa-modal">
        <div className="sa-modal-content">
          <div>{error}</div>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }

  return (
    <div className="sa-modal">
      <div className="sa-modal-content">
        <h2>Add New Sale</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Customer</label>
            <select
              name="customer_id"
              value={sale.customer_id}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Customer</option>
              {customers.map((customer) => (
                <option
                  key={customer.customer_id}
                  value={customer.customer_id}
                  data-name={customer.name}
                >
                  {customer.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Car</label>
            <select
              name="car_id"
              value={sale.car_id}
              onChange={handleCarChange}
              required
            >
              <option value="">Select Car</option>
              {cars.map((car) => (
                <option key={car.car_id} value={car.car_id}>
                  {car.model} - {car.make} ({car.year})
                </option>
              ))}
            </select>
          </div>
          {selectedCar && (
            <div className="form-group">
              <label>Car Price</label>
              <p>${selectedCar.price}</p>
            </div>
          )}
          <div className="form-group">
            <label>Sale Price</label>
            <input
              type="number"
              name="sale_amount"
              value={sale.sale_amount}
              onChange={handleInputChange}
              required
              min="0"
              step="0.01"
            />
          </div>
          <div className="form-group">
            <label>Amount Paid</label>
            <input
              type="number"
              name="amount_paid"
              value={sale.amount_paid}
              onChange={handleInputChange}
              required
              min="0"
              step="0.01"
            />
          </div>
          <div className="form-group">
            <label>Credit Amount</label>
            <input
              type="number"
              name="credit_amount"
              value={sale.credit_amount}
              onChange={handleInputChange}
              readOnly
            />
          </div>
          {validationError && (
            <p className="error-message">{validationError}</p>
          )}
          <div className="form-group">
            <label>Date</label>
            <input
              type="date"
              name="sale_date"
              value={sale.sale_date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-actions">
            <button type="submit" disabled={validationError}>
              Add Sale
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSaleModal;
